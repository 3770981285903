.App_Container {
    display: flex;
    height: 100%;
    padding: 0;
}

.App_Main {
    display: table-row;
    height: 100%;
    margin: auto;
    left: 0;
}
