@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~react-image-gallery/styles/css/image-gallery.css";

.goodStatus
{
    color:gray;
}

.errorStatus
{
    color: red;
}

.absolutePosition
{
    position: absolute;
}

.canvasOverlay
{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.relativePosition
{
    position: relative;
}

.videoCanvasContainer
{
    top: 0;
    left: 0;
    position: relative;
    width: fit-content;
    height: fit-content;
}

.maxWidthHeight100
{
    max-width:100%;
    max-height:100%;
}

.widthHeight100
{
    width:100%;
    height:100%;
}
.tagkastNavigationBar
{
    background-color: rgb(37,96,180);
}

.tagkastNavbarLogo
{
    content: url(./assets/images/tagkast_navbar_logo.png);
    max-width: 100%;
    max-height: 48px;
    object-fit: contain;
    height: 48px;
}

.form-signin
{
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.img-thumbnail
{
    transition: all .2s linear;
}

.img-thumbnail:hover {
    transform: scale(1.1);
}

.tagkast-gallery-thumbnail
{
    max-width: 256px;
    max-height: 256px;
}

.cameraPreview
{
    max-width:100%;
    max-height:100%;
}

.hidden
{
    visibility: hidden;
}

.applicationBackground
{
    position: absolute;
}

body, html {
    height: 100%;
}

.mainContainer {
    height:100%;
    display:table;
}

.heightFillAvailable {
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: stretch;
}

@keyframes flash {
    0% {
        background-color:white;
        alpha: 1f;
    }
    100% {
        background-color:transparent;
        alpha: 0f;
    }
}

.flashScreen {
    animation: flash 0.25s linear;
}

#root {
    height: 100%;
}

.fitContain {
    object-fit: contain;
}

.centerAbsoluteElementHorizontal {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.cameraPreviewIcons {
    width: 48px;
    height: 48px;
    size: 48px;
}
.react-draggable .list-group-item.list-group-item-flush
{
    padding: 0.2rem 0.4rem;
}

.mainContainer.container-fluid
{
    padding-left: 0px;
    padding-right:0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
    /* Styles */

    .cameraPreviewIcons {
        width: 48px;
        height: 48px;
        size: 48px;
    }
    .react-draggable .list-group-item.list-group-item-flush
    {
        padding: 0.15rem 0.3rem;
    }
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    /* Styles */
    .cameraPreviewIcons {
        width: 64px;
        height: 64px;
        size: 64px;
        font-size: 1.25em !important;
    }
}

@media only screen
and (orientation : landscape) {

    .PhotoShare_Img {
        width: 30%  !important;
    }
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    /* Styles */

    .cameraPreviewIcons {
        width: 48px;
        height: 48px;
        size: 48px;
    }

    .react-draggable .list-group-item.list-group-item-flush
    {
        padding: 0.3rem 0.3rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
    /* Styles */

    .cameraPreviewIcons {
        width: 64px;
        height: 64px;
        size: 64px;
        font-size: 1.25em !important;
    }
}
